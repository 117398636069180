import {
  Flex,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { Else, If, Then } from 'react-if';

import { AsyncWrapper } from 'components/AsyncWrapper';
import AsyncButton from 'components/form/AsyncButton';
import useAxios from 'hooks/useAxios';
import useUserContext from 'hooks/useUserContext';

export default function Billing(): JSX.Element {
  const { user } = useUserContext();
  const [paymentMethodRequest] = useAxios<boolean>('/api/billing/check-payment-method');
  const [signedUp, setSignedUp] = useState(!!user?.clinic?.usageBasedSubscriptionId);

  async function handleRedirectToCustomerPortal(): Promise<void> {
    const { data } = await axios.post('/api/billing/customer-portal');
    window.open(data.url, '_blank');
  }

  async function handleSignUpClick(): Promise<void> {
    await axios.post('/api/billing/usage-based-subscription');
    setSignedUp(true);
  }

  async function handleAddInvoiceItem(): Promise<void> {
    await axios.post('/api/billing/item/xcaliber');
  }

  return (
    <AsyncWrapper requests={[paymentMethodRequest]}>
      <Flex gap='20px' flexDirection='column'>
        <AsyncButton onClick={handleRedirectToCustomerPortal}>
          Manage Subscription
        </AsyncButton>
        <Flex>
          <Text>Sign up for usage charge subscription: </Text>
          <If condition={signedUp}>
            <Then>
              <Text>You are signed up.</Text>
            </Then>
            <Else>
              <AsyncButton onClick={handleSignUpClick}>
                Sign up
              </AsyncButton>
            </Else>
          </If>
        </Flex>
        <Flex>
          <Text>Adding an XCaliber item to usage charge subscription: </Text>
          <AsyncButton onClick={handleAddInvoiceItem}>
            Add
          </AsyncButton>
        </Flex>
        <Flex>
          <Text> Payment on file: </Text>
          <Text>{paymentMethodRequest.data ? 'yes' : 'no'}</Text>
        </Flex>
      </Flex>
    </AsyncWrapper>
  );
}
