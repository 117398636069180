import {
  Tab, TabList, TabPanel, TabPanels, Tabs,
} from '@chakra-ui/react';
import { When } from 'react-if';
import { useGate } from 'statsig-react';

import Billing from './Billing';
import ChangePassword from './ChangePassword';
import DicomServersList from './dicom/DicomServersList';
import EditClinic from './EditClinic';
import EditProfile from './EditProfile';
import PacsInfo from './PacsInfo';
import { StatsigGates } from 'StatsigGates';

function SettingsPage(): JSX.Element {
  const showUsageBilling = useGate(StatsigGates.USAGE_BILLED_BILLING).value;
  return (
    <Tabs>
      <TabList>
        <Tab>My Profile</Tab>
        <Tab>My Clinic</Tab>
        <Tab>PACS Info</Tab>
        <When condition={showUsageBilling}>
          <Tab>Billing</Tab>
        </When>
        <Tab>Dicom Servers</Tab>
        <Tab>Change Password</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <EditProfile />
        </TabPanel>
        <TabPanel>
          <EditClinic />
        </TabPanel>
        <TabPanel>
          <PacsInfo />
        </TabPanel>
        <When condition={showUsageBilling}>
          <TabPanel>
            <Billing />
          </TabPanel>
        </When>
        <TabPanel>
          <DicomServersList />
        </TabPanel>
        <TabPanel>
          <ChangePassword />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default SettingsPage;
